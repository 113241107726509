<template>
  <div class="policy">
    <div class="title">好運快篩器</div>
    <div class="subtitle">服務條款</div>
    <button @click="close">點我同意好運快篩器服務條款</button>
    <hr />
    <div class="content">
      當您使用「好運快篩器」時，即表示您已閱讀、瞭解並同意接受服務條款，本服務條款（以下統稱「本條款」）適用於任何存取或使用「好運快篩器」所提供之聊天機器人、網站、內容、產品。「好運快篩器」有權於任何時間修改或變更本條款之內容，建議您隨時注意該等修改或變更。您於任何修改或變更後繼續使用「好運快篩器」，都視為您已閱讀、瞭解並同意接受該等修改或變更。
    </div>
    <div class="item">一、使用者之守法義務與承諾</div>
    <div class="content">
      您可享有使用「好運快篩器」所提供之線上服務及相關資訊。但須承諾絕不為任何非法目的或以任何非法方式使用「好運快篩器」並承諾遵守中華民國相關法規及一切使用網際網路之國際慣例。您若係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。您同意並保證不得利用本服務從事侵害他人權益或違法之行為，包含違反法律或契約所應負之保密義務，冒用他人名義使用本服務，一旦有上述情形發生，「好運快篩器」有權停止相關服務權益。
    </div>
    <div class="item">二、個人資料之保護</div>
    <div class="content">
      當開始使用本服務時，「好運快篩器」所收集的所有個人資料會依照個人資料保護法及相關法令之規定，不會做為本平台服務外的其他用途，亦不會任意對其他第三者揭漏。
    </div>
    <div class="item">三、責任限制與免責</div>
    <div class="content">
      使用者應自行承擔使用本平台服務所衍生之責任，對於在本平台服務所從事的所有行為及其結果，使用者應自行負責一切責任。本服務如因系統問題而發生中斷或故障導致使用不便、資料遺失，將不負任何賠償責任。
    </div>
    <div class="item">四、拒絕或終止使用</div>
    <div class="content">
      使用者同意「好運快篩器」得基於維護使用者安全之考量，因違反本服務條款規定在通知或未通知之下，隨時可終止或限制使用本服務或其任何部分，「好運快篩器」對使用者或任何第三方均不承擔責任。
    </div>
    <div class="item">五、智慧財產權與隱私權保護</div>
    <div class="content">
      本服務所使用的軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由本平台或其他權利人依法擁有其智慧財產權（包括但不限於商標權、專利權、著作權、營業秘密與專有技術等）。任何人不得逕自修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您擬引用或轉載前述軟體、程式或網站內容，必須事先取得本平台或其他權利人的書面同意。尊重智慧財產權是所有人的應盡義務，如有違反，除您依法應負的法律責任外，本平台並得撤銷您的會員資格及永久禁止您使用本服務。
    </div>
    <div class="item">六、其他條款</div>
    <div class="content">
      本條款之準據法為中華民國法律。本條款之解釋悉依中國民國法律辦理。使用者與本公司因服務而涉訟時，雙方同意以台灣台南地方法院為第一審管轄法院。
    </div>
    <div class="item">七、隱私權聲明</div>
    <div class="content">
      以下為「命運掀牌手」獲得使用者個人資訊之相關資料收集方式、運用方法，以及保護網友網路隱私權的聲明（以下簡稱「本聲明」）。為了確保使用者之個人資料及權益之保護，依照個人資料保護法第八條規定進行下列資料蒐集前之告知：
      <ul>
        <li>
          資料蒐集之目的
          <p>
            在開始使用「好運快篩器」時，會請使用者選擇是否同意「好運快篩器」收集使用者的個人Line帳號提供的資料。同意之後，使用者即能享有「好運快篩器」的使用者隱私權保障權益。
          </p>
        </li>
        <li>
          如何使用我的資料？
          <p>
            「好運快篩器」收集的資料主要目的是作為「好運快篩器」實現與優化使用者體驗功能的使用。
          </p>
        </li>
        <li>
          隱私權保護適用範圍
          <p>
            本隱私權保護政策僅適用於您在本平台活動時，所涉及的個人資料蒐集、運用與保護，但不適用於您經由本平台搜尋連結至其他網站後，所進行之活動，關於您在其他網站的個人資料之保護，適用各該網站的隱私權保護政策。對於不屬於本平台之網頁，不論其內容或隱私權政策，均與本平台無關。本平台無須為您連結至其他網站所生之任何損害，負損害賠償之責任。
          </p>
        </li>
        <li>「好運快篩器」有隨時新增或修改本隱私權聲明之權利。</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Util from "@/mixins/util";
export default {
  name: "Policy",
  mixins: [Util],
  data() {
    return {
      liffId: "1656896964-1QpooNOB",
      title: "好運快篩器-服務條款",
    };
  },
  async created() {
    await this.setLiff(this.liffId);
  },
  methods: {
    async close() {
      try {
        await liff.sendMessages([
          {
            type: "text",
            text: "同意服務條款",
          },
        ]);
        await liff.closeWindow();
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy {
  font-family: "Noto Sans TC", sans-serif !important;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 100px;
}
.title {
  font-size: 24px;
  color: #0e0a32;
  font-weight: 500;
}
.subtitle {
  margin-top: 10px;
  font-size: 20px;
  color: #746eb2;
  font-weight: 500;
}
button {
  font-size: 18px;
  color: #fff;
  background-color: #00a99d;
  line-height: 65px;
  border-radius: 50px;
  position: fixed;
  bottom: 20px;
  width: 90%;
  left: 5%;
  font-weight: 400;
  border: none;
  box-shadow: 3px 3px 3px 0px #999;
}
hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  border-bottom: 1px solid #ddd;
}
.item {
  margin-top: 22px;
  font-size: 18px;
  color: #666;
  text-align: left;
  font-weight: 400;
}
.content {
  font-size: 18px;
  color: #666;
  text-align: left;
  font-weight: 400;
}
</style>
